.invoice-container {
  width: 70%;
  margin: auto;
  position: relative;
  left: 100px;
  padding: 20px 0px;
}
.invoice-main-block {
  padding: 15px;
  width: 100%;
}
.billing_invoice_header {
  display: flex;
}

.billing_invoice_heading {
  margin-top: 2px;
  font-size: 42px;
  color: black;
}
.invoice_head_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.billing_print_btn {
  padding: 4px 10px;
  border-radius: 6px;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: #06ae4b;
  margin: 10px;
}
.companyName-headerContainer {
  padding: 20px 0px;
  /* padding: 10px; */
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
}
.company-logo {
  height: 150px;
  width: 150px;
}
.company-nameAddress {
  height: 150px;
  width: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
}
.company-name {
  font-size: 30px;
  font-weight: 700;
}
.company-address {
  font-size: 16px;
  font-weight: 500;
}
.company-date {
  font-size: 16px;
  font-weight: 700;
}
.company-dateInvoiceNo {
  font-size: 20px;
  font-weight: 400;
  /* padding: 20px; */
}
.company-from {
  font-size: 22px;
  font-weight: 700;
}
.company-from-To {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}
.company-fromTo-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 2px solid black;
}
.Invoice-tableRow {
  background-color: lightgray !important;
}
.invoice-tableName {
  text-align: center !important;
  /* padding-left: 10px !important; */
  font-size: 16px !important;
  font-weight: 700 !important;
}
.Invoice-dataName {
  font-size: 14px !important;
  font-weight: 700 !important ;
  text-align: center !important;
}
.invoice-tableContainer {
  /* border: 1px solid black; */
  /* width: 80% !important ; */
  height: 500px;
  padding: 20px 0px;
}
.invoice-totalContainer {
  display: flex;
  justify-content: end;
  height: 200px;
  padding: 20px 10px;
}
.total-text {
  font-size: 18px;
  font-weight: 700;
}
.total-number {
  width: 150px;
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
  /* padding-top: 15px; */
}

.total-numberColum {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  /* padding-top: 10px; */
  text-align: center;
}
.invoice-thanksText {
  height: 50px;
  background-color: aqua;
  font-size: 22px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 20px 0px; */
}
.TaxInvoice-name {
  height: 50px;
  background-color: lightgreen;
  font-size: 22px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 20px 0px; */
}
