.productViewContainer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  /* left: 310px; */
  overflow: hidden;
}
.productViewMainContainer-header {
  display: flex;
  /* width: 100%; */
}
.billingViewMainContainer-header {
  display: flex;
}
.dashboardMainContainerHeader {
  display: flex;
  width: 75%;
  /* position: relative;
  left: 260px; */
}
.productView-name {
  width: 350px;
  height: 63px;
  position: relative;
  top: 1px;
  /* left: 160px ; */
  font-family: Rambla;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}
.billingView-name {
  width: 700px;
  height: 63px;
  padding-left: 10px;
  /* position: relative;
  top: 1px ;
  left: 250px ; */
  font-family: Rambla;
  font-size: 48px;
  font-weight: 700;
  /* text-align: center; */
}
.--dashboard {
  width: 260px;
  height: 63px;
  position: relative;
  top: 1px;
  left: 190px !important ;
  font-family: Rambla;
  font-size: 48px;
  font-weight: 700;
  /* text-align: center; */
}
.--Stock-dashboard {
  width: 500px;
  height: 63px;
  padding-left: 10px;
  /* position: relative;
  top: 1px ;
  left: 60px !important ; */
  font-family: Rambla;
  font-size: 48px;
  font-weight: 700;
  text-align: start;
}
.ProductView-btn {
  width: 150px;
  height: 42px;
  position: relative;
  top: 10px;
  right: 50px;
  border-radius: 10px 10px 10px 10px;
  background: rgba(6, 174, 75, 1);
  font-family: Rambla;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.input-container {
  /* width: 100%; */
  height: 630px;
  border-radius: 20px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 10px auto;
  /* position: relative;
  top: 20px;
  left: 290px; */
  /* overflow: hidden; */
}
.ProductDivField {
  width: 180px;
  padding: 5px;
  margin-top: 12px;
}
.ProductLabel {
  font-family: "Times New Roman", Times, serif;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}
.input-componentsField {
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 10px 14px;
  width: 330px;
  /* border: 2xp dashed; */
}

.addProductInputCss {
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 8px 14px;
  width: 160px;
  height: 40px;
}
.dashBoardInputCss {
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 8px 14px;
  width: 220px;
  height: 40px;
}
.addSalaryInputCss {
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 10px 6px;
  width: 240px;
}
.salaryAddInputFieldContainer {
  /* width: 300px; */
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
}
.addSalaryDescriptionInputCss {
  /* padding: 20px; */
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 10px 14px;
  width: 600px;
}

.input-datePickerField {
  /* width: 90%; */
  /* width: 380px !important;
  border-radius: 5px 5px 5px 5px;
  padding: 15px;
  font-family: Rambla;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  border: 1px solid rgba(211, 197, 197, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  margin-top: 10px; */
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 10px 14px;
  width: 330px;
  display: flex;
  flex-direction: column;
}

.input-field {
  display: flex;
  justify-content: space-around;
}
.inputSubmit-Btn {
  width: 150px;
  height: 40px;
  /* position: relative; */
  /* top: 40px; */
  /* left: 65px; */
  border-radius: 6px;
  background: rgba(6, 174, 75, 1);
  /* font-family: Rambla; */
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; */
}
.inputSubmit-Btn:hover {
  background-color: #00ff7f;
  outline: none;
  border: none;
  color: #000;
}
.chooseFileField {
  width: 90%;
  border-radius: 5px 5px 5px 5px;
  padding: 12px;
  font-family: Rambla;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  border: 1px solid rgba(211, 197, 197, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  margin-top: 10px;
}
.ViewProductMainContainer {
  width: 82%;
  position: relative;
  left: 265px;
}
.View-product-footer-container {
  /* width: 100%;  */
  height: 550px;
  background-color: rgba(217, 217, 217, 1);
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.View-product-GoBack-image {
  height: 60px;
  width: 60px;
  position: relative;
  left: 10px;
  top: 8px;
}
.View-product-header {
  width: 100%;
  height: 300px;

  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}
.Add-product-GoBack-image {
  height: 60px;
  width: 60px;
  position: relative;
  /* left: 300px; */
  top: 8px;
}
.billingView-backIcon {
  height: 60px;
  width: 60px;
  margin-top: 4px;
  /* position: relative;
  left: 260px;
  top: 8px; */
}
.--back-button {
  /* height: 60px; */
  /* width: 60px; */
  /* position: relative;
  /* left: 50px; */
  /* top: 8px;  */
  margin-left: 60px;
}
.view-product-search-field {
  width: 500px !important;
  height: 50px !important;
  margin: 20px 10px;
  font-weight: 600;
  font-size: 20px;
  border-radius: 10px;
  background: linear-gradient(
    92.11deg,
    rgba(0, 0, 0, 0.2) 0.28%,
    rgba(0, 0, 0, 0.2) 100%
  );
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: relative;
  /* right: 15px; */
  /* top: 15px; */
}
.view-product-search-field::placeholder {
  color: black;
  padding-left: 8px;
  /* width: 45%; */
}
.View-product-image-container {
  height: 330px;
  width: 22%;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* background-image: url(./productViewImages/productBackaroundImage.jpeg); */
  margin-top: 12px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 
.View-product-image-container:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
} */
.view-product-search-container {
  /* width: 45%; */
  /* height: 50px; */
  /* margin: 20px 10px; */
}
.view-product-addNewProduct-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.Product-first-image {
  width: auto;
  padding: 20px;
  border-radius: 10px;
}
.image-text-container {
  background-color: rgba(118, 118, 118, 0.448);
  font-size: 22px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 0pc 0px 10px 10px;
}
.View-product-addNewProduct {
  width: 45%;
  height: 50px;
  margin: 20px 10px;
  background-color: rgba(120, 220, 111, 1);
  font-family: Rambla;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.View-product-NoRecord {
  width: 100%;
  height: 330px;
  overflow-x: hidden;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backPageIcon {
  height: 60px;
  width: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  position: relative;
  top: 10px;
}
.BillingView-select {
  /* position: relative;
  left: 190px; */
  border-radius: 10px 10px 10px 10px;
  font-family: Rambla;
  font-size: 18px;
  font-weight: 800;
  text-align: end;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}
.View-select {
  height: 30px;
  width: 120px;
}
.billing_go-to-bill-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  position: relative;
  left: 260px;
}
.billing-header-container {
  background-color: rgba(6, 174, 75, 1);
  font-size: 22px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.billingView-nameHead {
  width: 12%;
  font-family: Rambla;
  font-size: 22px !important;
  font-weight: 700 !important ;
  color: white !important;
  text-align: center !important;
  overflow: hidden !important;
}
.billView-bodyName {
  font-family: Rambla;
  font-size: 16px !important;
  font-weight: 700 !important ;
  text-align: center !important;
  color: #000 !important;
}
.billView-mainContainer {
  width: 78%;
  height: 610px;
  border-radius: 20px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  position: relative;
  top: 10px;
  left: 290px;
}
/* .hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  scrollbar-color: transparent;
  scrollbar-width: 0;
} */

.billingView-pageChange-Container {
  position: relative;
}

.billingView-tablePagination {
  position: relative;
  bottom: 25px;
}
.billingView-pageShow {
  position: relative;
  top: 20px;
}

/* signIn --->> start */
/* 
.SignIn-mainContainer {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    296.75deg,
    rgba(6, 174, 75, 0.75) 3.2%,
    #02481f 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
}
.signIn-inner-container {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgba(209, 209, 209, 1);
  background: rgba(255, 255, 255, 1);
}

.signIn-companyLogo {
  width: 80px;
  height: 80px;
}

.signIn-header {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.signIn-companyName {
  padding-left: 10px;
  font-family: Rambla;
  font-size: 48px;
  font-weight: 700;
  text-align: left;
}

.signIn-labelName {
  font-family: Rambla;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.93px;
  text-align: left;
} */
.SignIn-mainContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url(./image/login.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: left;
  align-items: center;
}
.signIn-inner-container {
  padding: 1rem;
  border-radius: 5px;
  /* border: 1px solid rgba(209, 209, 209, 1); */
  /* background: rgba(255, 255, 255, 1); */
  /* background-color: lightblue; */
  /* box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  margin: 11rem;
}

.signIn-companyLogo {
  width: 80px;
  height: 80px;
}

.signIn-header {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.signIn-companyName {
  padding-left: 20px;
  padding-top: 10px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 40px;
  font-weight: 500;
  text-align: left;
}

.signIn-labelName {
  font-family: Rambla;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.93px;
  text-align: left;
}
.--model {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font-size: 22px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(209, 209, 209, 1);
  margin-bottom: 20px;
  box-shadow: none !important;
}

.singIn-input-form {
  padding: 20px 15px;
}
.signIn-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 25px;
  color: #fff;
  border: none;
  background: rgba(6, 174, 75, 0.84);
}
.signIn_error {
  color: red;
  font-size: 18px;
  text-align: left;
  font-weight: 500;
}
/* signIn --->> end */

/* dashBoard ---->>> end */
.DashBoard-mainContainer {
  width: 75%;
  position: relative;
  left: 280px;
}

.dashboard-main-container {
  width: 94%;
  /* height: 700px; */
  /* height: 100vh; */
  /* border-radius: 20px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding: 10px;
  position: relative;
  top: 10px;
  left: 172px;
  overflow: hidden;
}
.dashboard-saleReport {
  width: 340px;
  height: 190px;
  border-radius: 20px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
}

.dashboard-saleReport-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.dashboard-overall {
  width: 740px;
  height: 420px;
  border-radius: 20px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-creditDebitReport {
  width: 340px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-left: 20px !important; */
}

.dashboard-bottom-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 30px;
}

.sale-report-round {
  width: 165px;
  height: 165px;
  border: 2px solid #7ac8f3;
  background: conic-gradient(
    from 180.49deg at 50% 50%,
    #7ac8f3 0deg,
    #7ac8f3 179.43deg,
    #f6f6f6 180.54deg,
    #ffffff 360deg
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.sale-report-innerRound {
  width: 85px;
  height: 85px;
  background-color: white;
  border: 2px solid #7ac8f3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rambla;
  font-size: 36px;
  font-weight: 700;
  color: rgba(122, 200, 243, 1);
}

.sale-report-text {
  width: 150px;
  font-family: Rambla;
  font-size: 35px;
  font-weight: 700;
  color: rgba(122, 200, 243, 1);

  text-align: center;
}

.Purchase-report-round {
  width: 165px;
  height: 165px;
  border: 2px solid rgba(195, 134, 185, 1);
  background: conic-gradient(
    from 180.49deg at 50% 50%,
    #c386b9 0deg,
    #c386b9 253.17deg,
    #f6f6f6 253.32deg,
    #ffffff 360deg
  );

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.Purchase-report-innerRound {
  width: 85px;
  height: 85px;
  background-color: white;
  border: 2px solid rgba(195, 134, 185, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rambla;
  font-size: 36px;
  font-weight: 700;
  color: rgba(195, 134, 185, 1);
}
.Purchase-report-text {
  width: 150px;
  font-family: Rambla;
  font-size: 35px;
  font-weight: 700;
  color: rgba(195, 134, 185, 1);

  text-align: center;
}

.Revenue-report-round {
  width: 165px;
  height: 165px;
  border: 2px solid rgba(251, 176, 111, 0.8);
  background: conic-gradient(
    from 180.49deg at 50% 50%,
    #fbb06f 0deg,
    #fbb06f 214.78deg,
    #f6f6f6 214.82deg,
    #ffffff 360deg
  );

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.Revenue-report-innerRound {
  width: 85px;
  height: 85px;
  background-color: white;
  border: 2px solid rgba(251, 176, 111, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rambla;
  font-size: 36px;
  font-weight: 700;
  color: rgba(251, 176, 111, 0.8);
}
.Revenue-report-text {
  width: 150px;
  font-family: Rambla;
  font-size: 35px;
  font-weight: 700;
  color: rgba(251, 176, 111, 0.8);
  text-align: center;
}

.credit-report-round {
  width: 165px;
  height: 165px;
  border: 2px solid rgba(238, 36, 57, 1);
  background: conic-gradient(
    from 180.49deg at 50% 50%,
    #f18b99 0deg,
    #f18b99 144.42deg,
    #f6f6f6 144.46deg,
    #ffffff 360deg
  );

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.credit-report-innerRound {
  width: 85px;
  height: 85px;
  background-color: white;
  border: 2px solid rgba(238, 36, 57, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rambla;
  font-size: 36px;
  font-weight: 700;
  color: rgba(238, 36, 57, 1);
}

.credit-report-text {
  width: 150px;
  font-family: Rambla;
  font-size: 35px;
  font-weight: 700;
  color: rgba(241, 139, 153, 1);
  text-align: center;
}

.debit-report-round {
  width: 165px;
  height: 165px;
  border: 2px solid rgba(27, 207, 180, 1);
  background: conic-gradient(
    from 180.49deg at 50% 50%,
    #1bcfb4 0deg,
    #1bcfb4 160.64deg,
    #f6f6f6 161.15deg,
    #ffffff 360deg
  );

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.debit-report-innerRound {
  width: 85px;
  height: 85px;
  background-color: white;
  border: 2px solid rgba(27, 207, 180, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rambla;
  font-size: 36px;
  font-weight: 700;
  color: rgba(27, 207, 180, 1);
}

.debit-report-text {
  width: 150px;
  font-family: Rambla;
  font-size: 35px;
  font-weight: 700;
  color: rgba(27, 207, 180, 1);
  text-align: center;
}
/* dashBoard ---->>> end */

/* Customer Register table start */

.product_container {
  position: relative;
  left: 120px;
  width: 82% !important;
}

.product_table_container {
  width: 100%;
  height: 750px;
  margin: 10px;
  border-radius: 15px 15px 15px 15px;
  padding-top: 20px;
  box-shadow: 0px 2px 10px 0px #1400ff40;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
}
.product_heading {
  background-color: #06ae4b;
}
.product_table_heading {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
}
.product_invoice_print_icon {
  width: 30px;
  height: 30px;
  gap: 10px !important;
}
.product_edit_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.product_delete_icon {
  width: 20px;
  height: 20px;
}

.product_table_data {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  /* line-height: 23.93px !important; */
  text-align: center !important;
}
.product_btn_footer {
  display: flex;
  justify-content: end;
}

.product_view {
  width: max-content;
  font-family: Rambla;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.75px;
  text-align: left;
  display: flex;
}
.product_paragraph_footer {
  font-family: Rambla;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.93px;
  text-align: left;
  display: flex;
  justify-content: start;
}
.product_status_button {
  display: flex;
  justify-content: end;
}
.product_header {
  display: flex;
  justify-content: start;
}
.product_status {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24.48px !important;
  text-align: center !important;
}
.select_option {
  width: 120px;
  height: 40px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
}
.product_image {
  width: 60px;
  height: 40.63px;
  top: 84px;
  left: 295px;
  gap: 0px;
  opacity: 0px;
}

.prev_next_icon {
  display: flex;
  justify-content: end;
}
.product_heading_sticky {
  background-color: #06ae4b;
}

.product_table_container_sticky {
  width: 100% !important;
  margin: 10px;
  border-radius: 15px 15px 15px 15px;
  padding-top: 20px;
  box-shadow: 0px 2px 10px 0px #1400ff40;
  /* overflow: hidden !important; */
  background-color: #ffffff !;
  overflow-x: auto !important;
}
.product_table_heading_first_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 0px !important;
  top: 0px;
  background-color: #06ae4b;
}
.product_table_heading_second_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 80px;
  top: 0px;
  background-color: #06ae4b;
}
.product_table_heading_third_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 189px;
  top: 0px;
  background-color: #06ae4b;
}
.product_table_heading_fourth_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 298px;
  top: 0px;
  background-color: #06ae4b;
  z-index: 1000;
}
.product_table_heading_five_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 408px;
  top: 0px;
  background-color: #06ae4b;
  z-index: 1000;
}
.product_table_data_first_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  /* line-height: 23.93px !important; */
  text-align: center !important;
  position: sticky;
  left: 0px;
  top: 0px;
}
.product_table_data_second_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  /* line-height: 23.93px !important; */
  text-align: center !important;
  position: sticky;
  left: 85px;
  top: 0px;
}
.product_table_data_third_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  /* line-height: 23.93px !important; */
  text-align: center !important;
  position: sticky;
  left: 195px;
  top: 0px;
}
.product_table_data_four_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  /* line-height: 23.93px !important; */
  text-align: center !important;
  position: sticky;
  left: 310px;
  top: 0px;
  background-color: #ffffff;
}
.product_table_data_five_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  /* line-height: 23.93px !important; */
  text-align: center !important;
  position: sticky;
  left: 408px;
  top: 0px;
  background-color: #ffffff;
}
/* 
.sales_table_heading_first_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 10px;
  top: 0px;
  background-color: #06ae4b;
}
.sales_table_heading_second_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 54px;
  top: 0px;
  background-color: #06ae4b;
}
.sales_table_heading_third_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 178px;
  top: 0px;
  background-color: #06ae4b;
}
.sales_table_heading_fourth_sticky {
  font-family: Rambla !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 19px !important;
  position: sticky;
  left: 295px;
  top: 0px;
  background-color: #06ae4b;
}
.sales_table_data_first_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  text-align: center !important;
  
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: #ffffff !important;
}
.sales_table_data_second_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  text-align: center !important;
  position: sticky;
  left: 54px;
  top: 0px;
  background-color: #ffffff !important;
}
.sales_table_data_third_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  text-align: center !important;
  position: sticky;
  left: 178px;
  top: 0px;
  background-color: #ffffff !important;
}
.sales_table_data_four_sticky {
  font-family: Rambla !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  text-align: center !important;
  position: sticky;
  left: 295px;
  top: 0px;
  background-color: #ffffff;
}

.sales_edit_icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.sales_delete_icon {
  height: 20px;
  width: 20px;
} */

/* .customer-Table-container{
  width: 80%;
  position: relative;
  left: 150px;
} */
.customerInputField {
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 6px;
  font-size: 17px;
  padding: 10px 14px;
  width: 230px;
  color: gray;
}
.deleteBtnCss {
  /* width: 150px; */
  display: flex;
  justify-content: end;
  /* margin-right: 61px; */
}
.deleteBtn {
  height: 30px;
  width: 190px;
  font-size: 18px;
  background-color: #06ae4b;
  color: #fff;
  border: none;
  border-radius: 6px;
}
/* modal Css start */
.modalSubmitBtn {
  height: 40px;
  width: 70px;
  border: none;
  border-radius: 6px;
  background-color: #06ae4b;
}
/* modal Css end */
.css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 0px !important;
  height: 40px !important;
}
/* customer register table end  */
@media screen and (max-width: 1400px) {
  .dashboard-main-container {
    width: 100% !important;
  }
  .dashboard-bottom-container {
    gap: 10px !important;
  }
}

/* .billing_input_field {
  width: 100%;
  height: 50px;
  transition: height 0.1s ease;
} */

.billing_input_field_select:focus {
  /* height: 150px;  */
  width: 711px;
 
}
