.BillingMainContainer{
    position: relative;
    /* overflow: hidden; */
    width: 81%;
    left:250px;
}
.BillingViewHeader{
    width: 100%;
    display: flex;
}
.Billing-View-Heading{
    font-family: Rambla;
font-size: 48px;
font-weight: 700;
}
.Billing-View-GoBackBtn{
width: 60px;
height: 60px;

}
.Billing-View-SearchField{
    width: 125px;
height: 25px;
border-radius: 5px;
background: white;
border: 2px solid black;
margin: 20px;
padding-left:10px;
padding-bottom: 4px;
color: black;

}

.Billing-View-Search-View-Billing{
    display: flex;
    justify-content: space-between;
}

.view-product-search-field{
    width: 180px;
    height: 30px;
    font-weight: 600;
    font-size: 20px;
    border-radius: 5px 5px 5px 5px;
    background: linear-gradient(
      92.11deg,
      rgba(0, 0, 0, 0.2) 0.28%,
      rgba(0, 0, 0, 0.2) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.5);
 
}
.Billing-View-View-Btn{
width: 140px;
height: 40px;
border-radius: 8px ;
background: #06AE4B;
font-family: Inter;
font-size: 18px;
font-weight: 800;
line-height: 21.78px;
color: white;
margin: 10px;

}
.Billing-View-GotoBillBtn{width: 140px;
    height: 40px;
    border-radius: 8px ;
    background: #06AE4B;
    font-family: Inter;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.78px;
    color: white;
    margin: 10px;
    
}
.Billing-View-Product-Image{
width: 210px;
height: 230px;
}
.Billing-View-Product-Container{
display: flex;
flex-wrap: wrap;
width: 100%;
/* height: 360px; */
background: #D9D9D9;
/* overflow-y: scroll; */
padding-top: 20px;
}
.Billing-View-Div-Block{
    /* background-image: url('../../src/assets/image/Rectangle 51.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-right: 8px;
    height: 330px;
    width: 250px;
    margin-left: 20px;
    margin-bottom: 20px;
}
.BillingViewImageSection{
    margin-top: 10px;
}
.BillingViewImageHeading{
font-family: Rambla;
font-size: 22px;
font-weight: 700;
line-height: 26.93px;
text-align: left;
width: 196px;
height: 27px;
}
.Billing-View-Product-Header{
    width: 231px;
    height: 70px;
    margin-right: 6px;
    border-radius: 0px 0px 14px 15px;
    margin-bottom: 3px;
display: flex;
justify-content: center;
align-items: center;
font-family: Rambla;
font-size: 22px;
font-weight: 700;
background: linear-gradient(95.14deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
background-size: cover !important;
}
