.product_container {
    position: relative;
    left: 120px;
    width: 82% !important;
  }
  .productViewHeadBlock{
    display:flex;
  }
  .productViewSearchFilterBlock{
    display: flex;
    width:67%;
    justify-content: end;
    margin-top: 15px;
  
  }
  .productViewSearchField{
    margin-right: 20px;
    margin-top: 4px;
    font-weight: bold;
  }
  .productViewHeadSection{
    display: flex;
    justify-content: space-between;
  }
  .product_table_container {
    width: 100%;
    height: 750px;
    margin: 10px;
    border-radius: 15px 15px 15px 15px;
    padding-top: 20px;
    box-shadow: 0px 2px 10px 0px #1400ff40;
    background: #ffffff;
    display: flex;
    flex-wrap: wrap;
  }
  .product_heading {
    background-color: #06ae4b;
  }
  
  .product_invoice_print_icon {
    width: 30px;
    height: 30px;
    gap: 10px !important;
  }
  .product_edit_icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .product_delete_icon {
    width: 20px;
    height: 20px;
  }
  
  .product_table_data {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 195px !important;
  }
  .product_btn_footer {
    display: flex;
    justify-content: end;
  }
  
  .product_view {
    font-family: Rambla;
    font-size: 48px;
    font-weight: 700;
    line-height: 58.75px;
    text-align: left;
    display: flex;
  }
  .product_paragraph_footer {
    font-family: Rambla;
    font-size: 22px;
    font-weight: 400;
    line-height: 26.93px;
    text-align: left;
    display: flex;
    justify-content: start;
  }
  .product_status_button {
    display: flex;
    justify-content: end;
  }
  .product_header {
    display: flex;
    justify-content: start;
  }
  .product_status {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24.48px !important;
    text-align: center !important;
  }
  .select_option {
    width: 120px;
    height: 40px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 0px;
  }
  .product_image {
    width: 60px;
    height: 40.63px;
    top: 84px;
    left: 295px;
    gap: 0px;
    opacity: 0px;
  }
  
  .prev_next_icon {
    display: flex;
    justify-content: end;
  }
  .product_heading_sticky {
    background-color: #06ae4b;
  }
  
  .product_table_container_sticky {
    width: 80% !important;
    margin: 10px;
    border-radius: 15px 15px 15px 15px;
    padding-top: 20px;
    box-shadow: 0px 2px 10px 0px #1400ff40;
    background-color: #ffffff !important;
    overflow-x: auto !important;
  }
  .customer_table_container_sticky {
    width: 98% !important;
    margin: 10px;
    border-radius: 15px 15px 15px 15px;
    padding-top: 20px;
    box-shadow: 0px 2px 10px 0px #1400ff40;
    background-color: #ffffff !important;
    overflow-x: auto !important;
  }
 

  .customerRegisterMainBlock{
    /* border: 2px solid green; */
    width:81%;
    position:relative;
    left:248px;
  }
  .product_table_heading {

    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    min-width: 195px !important;
  }
  .product_table_heading_first_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    left: 0px !important;
    min-width: 100px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .product_table_heading_second_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    left: 100px !important;
    top: 0px;
    min-width: 195px !important;
    background-color: #06ae4b;

  }
  .product_table_heading_third_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    left: 295px !important;
    min-width: 195px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .product_table_heading_fourth_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    left: 490px !important;
    min-width: 195px !important;
    top: 0px;
    background-color: #06ae4b;
    z-index: 1000;
  }
  .product_table_heading_five_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 19px !important;
    position: sticky;
    left: 685px !important;
    min-width: 195px !important;

    top: 0px;
    background-color: #06ae4b;
   
  }
  .product_table_data_first_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    left: 0px !important;
    min-width: 100px !important;
    top: 0px;
    background-color: #ffffff;
  }
  .product_table_data_second_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    left: 100px !important;
    min-width: 195px !important;
    background-color: #ffffff;
    top: 0px;
  }
  .product_table_data_third_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    left: 295px !important;
    min-width: 195px !important;
    background-color: #ffffff;
    top: 0px;
  }
  .product_table_data_four_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    left: 490px !important;
    min-width: 195px !important;
    top: 0px;
    background-color: #ffffff;
  }
  .product_table_data_five_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    left: 685px !important;
    min-width: 120px !important;
    top: 0px;
    background-color: #ffffff;
    
  }
  .sales_table_heading{
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    max-width: 120px !important;
    padding: 10px 0px !important;
  }
  .sales_table_data{
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    max-width: 120px !important;
  }
  .sales_table_data--productName{
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    max-width: 120px !important;
    /* word-wrap: break-word !important; */
  }
  .sales_table_data--productName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  } 
 .sales_table_data--productName  {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
            line-clamp: 2;
    -webkit-box-orient: vertical;
 }
 .sales_table_data--productName::hover {
  -webkit-line-clamp: unset !important; /* Remove line clamping on hover */
  white-space: normal !important; /* Allow text to wrap */
  overflow: visible !important;
  background-color: #fff; /* Optional: to differentiate the hovered text */
  z-index: 1; /* Ensure it appears above other elements */
}
  .sales_table_data--customer{
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    max-width: 120px !important;
    /* background-color: #06ae4b !important; */
  }
  
  .sales_table_heading_first_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position:sticky !important;
    min-width:195px !important;
    left: 0px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .sales_table_heading_second_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
  position:sticky !important;
  min-width: 195px !important;
    left: 195px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .sales_table_heading_third_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 390px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .sales_table_heading_fourth_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 585px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .sales_table_heading_fivth_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 780px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .sales_table_data_first_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 0px !important;
    top: 0px;
    background-color: #ffffff !important;
  }
  .sales_table_data_second_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 195px !important;
    top: 0px;
    background-color: #ffffff !important;
  }
  .sales_table_data_third_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 390px !important;
    top: 0px;
    background-color: #ffffff !important;
  }
  .sales_table_data_fourth_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 585px !important;
   
    top: 0px;
    background-color: #ffffff;
  }
  .sales_table_data_fivth_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 780px !important;
    top: 0px;
    background-color: #ffffff;
  }
  .customer_table_heading{
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    min-width: 195px !important;
    background-color: #06ae4b !important;
    
  }
  .tableborder{
    border-bottom: 1px solid  gray !important;
   }
  .customer_table_data{
    font-family: Rambla !important;
    font-size: 15px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 195px !important;
   /* border-bottom: 1px solid black !important; */
    background-color: #ffffff !important;
  }
  
  .customer_table_heading_first_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position:sticky !important;
    min-width:195px !important;
    left: 0px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .customer_table_heading_second_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
  position:sticky !important;
  min-width: 195px !important;
    left: 195px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .customer_table_heading_third_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 390px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .customer_table_heading_fourth_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 585px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .customer_table_data_first_sticky{
    font-family: Rambla !important;
    font-size: 15px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 0px !important;
    top: 0px;
    background-color: #ffffff !important;
   /* border-bottom: 1px solid black !important; */


  }
  .customer_table_data_second_sticky {
    font-family: Rambla !important;
    font-size: 15px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 195px !important;
    top: 0px;
    background-color: #ffffff !important;
   /* border-bottom: 1px solid black !important; */

  }
  .customer_table_data_third_sticky {
    font-family: Rambla !important;
    font-size: 15px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 390px !important;
    top: 0px;
    background-color: #ffffff !important;
   /* border-bottom: 1px solid black !important; */

  }
  .customer_table_data_fourth_sticky {
    font-family: Rambla !important;
    font-size: 15px !important;
    font-weight: 200 !important;
    text-align: center !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 585px !important;
    /* border-bottom: 1px solid black !important; */
    top: 0px;
    background-color: #ffffff !important;
  }

  .customerViewHeadSection{
    display: flex;
    width:92%;
    /* border: 2px solid red; */
    justify-content: space-between;
  }
  .customerViewSearchField{
    margin-top: 20px;
    /* position: relative;
    left:0px; */
  }
  .customerViewHeading{
    font-family: Rambla;
    font-size: 48px;
    font-weight: 700;
  }
  .purchase_table_heading{
    
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    min-width: 195px !important;
  }
  .purchase_table_data{
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 195px !important;
  }
  .purchase_table_heading_first_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 19px !important;
    position: sticky !important;
    left: 0px;
    top: 0px;
    min-width: 100px !important;
    background-color: #06ae4b;
  }
  .purchase_table_heading_second_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 19px !important;
    position: sticky !important;
    min-width: 195px !important;
    left: 100px;
    top: 0px;
    background-color: #06ae4b;
  }
  .purchase_table_heading_third_sticky {
    font-family: Rambla !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 19px !important;
    min-width: 195px !important;
    position: sticky !important;
    left: 295px !important;
    top: 0px;
    background-color: #06ae4b;
  }
  .purchase_table_data_first_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 100px !important;
    position: sticky !important;
    left: 0px !important;
    top: 0px;
    background-color: white;
    
  }
  .purchase_table_data_second_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 195px !important;
    position: sticky !important;
    left: 100px !important;
    top: 0px;
    background-color: #ffffff !important;
  }
  .purchase_table_data_third_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 195px !important;
    position: sticky !important;
    left: 295px !important;
    top: 0px;
    background-color: #ffffff !important;
  }
  
  .purchase_table_data_fourth_sticky {
    font-family: Rambla !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    text-align: center !important;
    min-width: 195px !important;
    position: sticky !important;
    left: 350px !important;
    top: 0px;
    background-color: #ffffff !important;
  }
  
  .sales_edit_icon {
    height: 30px;
    width: 25px;
    margin-right: 10px;
    padding-bottom: 10px;
  }
  .sales_delete_icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  .sales_print_icon {
    height: 31px;
    width: 31px;
    margin-bottom: 10px;
  }
.salesViewSearchField{
  display: flex;
  width:73%;
  justify-content: end;
  margin-top: 15px;
}
.purchaseViewSearchField{
margin-left: 150px;
margin-top: 3px;
}